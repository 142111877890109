import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  WrapperRef,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem,
  DownArrow,
  DropDownSelect
} from "./Styles";
import Arrow from "../../assets/images/down-arrow.png";

const Dropdown = ({
  valueSelected,
  onChange,
  initialLabel,
  options,
  dataTestId,
  name,
  id,
  onBlur
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [wrapperRef]);

  const toggling = () => {
    setIsOpen(!isOpen);
    if(!isOpen && !optionSelected){
        onBlur();
    }
  };

  const onOptionClicked = (value) => () => {
    onChange(value);
    setIsOpen(false);
  };

  const optionSelected = options.find((option) => option.id === valueSelected);

  const value = optionSelected ? optionSelected.value : null;

  return (
    <WrapperRef ref={wrapperRef}>
      <DropDownHeader
        isOpen={isOpen}
        onClick={toggling}
        data-testid={dataTestId}
        value={value}
        className="ddl"
        name={name}
        id={id}
      >
        <p>{value || initialLabel}</p>
        <DownArrow isOpen={isOpen} src={Arrow} />
      </DropDownHeader>

      {isOpen && (
        <DropDownListContainer>
          <DropDownSelect showScroll={options.length > 5}>
            <DropDownList>
              {options.map((option, i) => (
                <ListItem
                  onClick={onOptionClicked(option)}
                  key={option.id}
                  widthOption={document.querySelector(".ddl").offsetWidth}
                  id={option.id}
                  data-testid={`list-item-${i}`}
                  value={option.value}
                >
                  {option.value}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownSelect>
        </DropDownListContainer>
      )}
    </WrapperRef>
  );
};

Dropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  valueSelected: PropTypes.string.isRequired,
  initialLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      id: PropTypes.string
    })
  ).isRequired,
  dataTestId: PropTypes.string,
  onBlur: PropTypes.func.isRequired
};

Dropdown.defaultProps = {
  initialLabel: "Selecciona",
  dataTestId: "test-custom-dropdown"
};

export default Dropdown;