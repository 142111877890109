import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Input from "../../components/Input/Input";
import Select from "../../components/Select/Select";
import Dropdown from "../../components/Dropdown/Dropdown";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import InvestmentCancellationActions from "../../redux/reducers/investment-cancellation.reducer";
import { numericValue, numberToMoney, handleAmountChange } from "../../lib/formatter";
import User from "../../lib/models/user.model";
import "./MoneyWithdrawal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import moneyWithdrawalSchema from "../MoneyWithdrawal/moneyWithdrawal.schema";

const MoneyWithdrawal = ({ location }) => {
  const { isValidated } = location.state;
  const dispatch = useDispatch();
  const availableMoney = useSelector(({ balance }) => balance.availableMoney);
  const userData = useSelector(({ user }) => user.userInfo.user);
  const { t } = useTranslation();
  const [selectPersonType, setPersonType] = useState(userData.person_type);
  const investmentCancellation = useSelector(
    (store) => store.investmentCancellation
  );

  const documentTypes = [
    { value: 1, text: "CC" },
    { value: 2, text: "CE" },
    { value: 3, text: "NIT" }
  ];

  const handleBack = () => {
    dispatch(push("/"));
  };

  // eslint-disable-next-line no-underscore-dangle
  const banks = location.state.response[1]._bank.map((bank) => ({
    value: `${bank.name}`.toUpperCase(),
    id: `${bank.id}`
  }));
  
  // eslint-disable-next-line no-underscore-dangle
  const bankAccountTypes = location.state.response[1]._bank_account_type.map(
    (type) => ({ id: `${type.id}`, value: `${type.name}`.toUpperCase() })
  );

  const initialValues = {
    documentType:
      userData.person_type === User.type.natural
        ? documentTypes[0].value
        : documentTypes[2].value,
    amount: "0",
    personType: "1",
    bankName: "",
    bank: "",
    firstLastName: userData.last_name,
    confirmDocumentNumber: "",
    confirmBankNumber: "",
    names: userData.name,
    documentNumber: userData.document_number,
    company: "",
    bankAccountType: "",
    bankNumber: "",
    companyName: userData.name,
    dv2: userData.dv,
    secondLastName: userData.second_last_name || ""
  };

  const getFullNameUser = () => {
    if (selectPersonType === User.type.legal) {
      return userData.name;
    }

    let fullName = userData.name;

    if (userData.last_name) {
      fullName = `${fullName} ${userData.last_name}`;
    }
    if (userData.second_last_name) {
      fullName = `${fullName} ${userData.second_last_name}`;
    }

    return fullName;
  };

  const handleSubmitWithdrawal = (values) => {
    const bank = banks.find((item) => item.id === values.bank);
    const bankName = bank.value;
    const payload = {
      amount: Number(numericValue(values.amount)),
      bank: values.bank,
      bank_account_type: values.bankAccountType,
      account_number: values.bankNumber,
      bank_name: bankName,
      holder_names: values.names,
      holder_first_last_name: values.firstLastName,
      holder_second_last_name: values.secondLastName,
      holder_document_number: values.documentNumber,
      holder_document_type: values.documentType
    };

    if (selectPersonType === User.type.legal) {
      delete payload.holder_first_last_name;
      delete payload.holder_second_last_name;
      payload.holder_names = values.companyName;
      payload.holder_dv = values.dv2;
    }

    dispatch(
      InvestmentCancellationActions.createWithdrawalTransaction(payload)
    );
  };

  useEffect(
    () => () => {
      dispatch(InvestmentCancellationActions.cleanIsRequesting());
    },
    []
  );

  return (
    <div className="container">
      <div className="money-withdrawal__back_container">
        <button data-testid="money-withdrawal-go-back" className="money-withdrawal__go__back" onClick={handleBack}>
          <div className="money-withdrawal__balance-content-button">
            <FontAwesomeIcon
              className="money-withdrawal__balance-content-button__icon"
              icon={faChevronLeft}
            />
            <p>Volver</p>
          </div>
        </button>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={moneyWithdrawalSchema(selectPersonType, availableMoney)}
        id="withdrawal-form"
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          handleSubmitWithdrawal(values);
        }}
      >
        {({
          values,
          errors,
          isValid,
          dirty,
          handleSubmit,
          setFieldValue,
          handleChange,
          handleBlur,
          setTouched,
          touched
        }) => {
          const handleBankChange = (e) => {
            setFieldValue("bank", e.id);
          };
          const handleBankTypeChange = (e) => {
            setFieldValue("bankAccountType", e.id);
          };
          const handleBankBlur = () => {
            setTouched({ ...touched, bank: true });
          };
          const handleBankTypeBlur = () => {
            setTouched({ ...touched, bankAccountType: true });
          };
          return isValidated && (
            <form
              className="money-withdrawal__container"
              onSubmit={handleSubmit}
              data-testid="withdrawal-data-container-form"
            >
              <div className="money-withdrawal__intro__container">
                <h1 className="money-withdrawal__title">{t("moneyWithdrawal.title")}</h1>
                <p className="money-withdrawal__subtitle">
                  <strong>{getFullNameUser()}</strong>
                  {t("moneyWithdrawal.description")}
                </p>
              </div>
              <div className="money-withdrawal__row__container">
                <div className="money-withdrawal__row">
                  <div className="money-withdrawal__column">
                    <h4 className="money-withdrawal__row__title"> {"Saldo disponible"}</h4>
                    <h3 className="money-withdrawal__available__money">
                      {numberToMoney(availableMoney, "COP $", ".", ",", ".", true, true)}
                    </h3>
                  </div>
                  <div data-testid="moneyWithdrawal-amount-container" className="money-withdrawal__column">
                    <h5 className="money-withdrawal__column__title">{t("moneyWithdrawal.amount")}</h5>
                    <div className={touched.amount && errors.amount ? "money-withdrawal__column__same__row money-withdrawal__input__container money-withdrawal__input__container__error" : "money-withdrawal__column__same__row money-withdrawal__input__container"}>
                      <span className={"money-withdrawal__input__container__prefix"}>{"COP"}</span>
                      <div className="money-withdrawal__input__container__amount__field__divider"></div>
                      <Input
                        type="text"
                        name="amount"
                        label={t("moneyWithdrawal.amount")}
                        showTooltip={false}
                        data-testid="amount-input"
                        placeholder={t("moneyWithdrawal.amount")}
                        className={"money-withdrawal__input__container__amount__field"}
                        extraClassname={"money-withdrawal__input__withdrawal"}
                        value={`${values.amount}`}
                        onBlur={handleBlur}
                        error={touched.amount && errors.amount}
                        onChange={(val) => {
                          setFieldValue("amount", handleAmountChange(val.target.value, values.amount));
                        }}
                      />
                    </div>
                    {(touched.amount && errors.amount) && (
                      <p data-testId="alert-error-amount" className="money-withdrawal__input__container__text__alert">
                        {t(errors.amount)}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="money-withdrawal__stroke-line-container">
                <div className="money-withdrawal__stroke-circle" />
                <div className="money-withdrawal__stroke-line" />
              </div>
              <div className="money-withdrawal__row__container">
                <h4 className="money-withdrawal__row__title">{t("moneyWithdrawal.accountHolder")}</h4>
              </div>
              <div className="money-withdrawal__row__container">
                <div className="money-withdrawal__row">
                  {selectPersonType === User.type.legal ? (
                    <>
                      <div className="money-withdrawal__column">
                        <h5 className="money-withdrawal__column__title">{t("moneyWithdrawal.companyName")}</h5>
                        <Input
                          label={t("moneyWithdrawal.companyName")}
                          hasBorder={true}
                          name="companyName"
                          disabled={true}
                          onBlur={handleBlur}
                          id="company_name"
                          className={"money-withdrawal__input__container"}
                          extraClassname={"money-withdrawal__input__withdrawal"}
                          onChange={handleChange}
                          value={values.companyName}
                        />
                      </div>
                      <div className="money-withdrawal__column">
                        <h5 className="money-withdrawal__column__title">{t("moneyWithdrawal.documentNumber")}</h5>
                        <div className="money-withdrawal__column__same__row">
                          <Select
                            isSummary={true}
                            label={t("register.documentType")}
                            type="documentType"
                            disabled={true}
                            name="documentType"
                            testId="document-type-input"
                            className="money-withdrawal__select__withdrawal__document__type money-withdrawal__select__withdrawal__small money-withdrawal__select__withdrawal"
                            id="documentType"
                            options={documentTypes}
                            onBlur={handleBlur}
                            value={values.documentType}
                          />
                          <div className="money-withdrawal__input__container__amount__field__divider money-withdrawal__input__container__amount__field__divider__disabled"></div>
                          <Input
                            label={t("moneyWithdrawal.documentNumber")}
                            name="documentNumber"
                            disabled={true}
                            id="document_number"
                            className={"money-withdrawal__input__container__document__field money-withdrawal__input__container__medium"}
                            extraClassname={"money-withdrawal__input__withdrawal money-withdrawal__input__withdrawal__no__radius money-withdrawal__input__withdrawal__align__left"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.documentNumber}
                          />
                          <div className="money-withdrawal__input__container__amount__field__divider money-withdrawal__input__container__amount__field__divider__disabled"></div>
                          <Input
                            label={t("moneyWithdrawal.dv")}
                            type="dv"
                            name="dv2"
                            id="dv2"
                            disabled={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.dv2}
                            error={touched.dv2 && errors.dv2}
                            className={"money-withdrawal__input__container__document__field money-withdrawal__input__container__small"}
                            extraClassname={"money-withdrawal__input__withdrawal money-withdrawal__input__withdrawal__no_left__radius money-withdrawal__input__withdrawal__align__center"}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="money-withdrawal__column">
                        <h5 className="money-withdrawal__column__title">{t("moneyWithdrawal.names")}</h5>
                        <Input
                          label={t("moneyWithdrawal.names")}
                          hasBorder={true}
                          name="names"
                          id="names"
                          disabled={true}
                          className={"money-withdrawal__input__container"}
                          extraClassname={"money-withdrawal__input__withdrawal"}
                          onChange={handleChange}
                          value={values.names}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="money-withdrawal__column">
                        <h5 className="money-withdrawal__column__title">{t("moneyWithdrawal.firstLastName")}</h5>
                        <Input
                          label={t("moneyWithdrawal.firstLastName")}
                          name="firstLastName"
                          id="first_last_name"
                          disabled={true}
                          onBlur={handleBlur}
                          className={"money-withdrawal__input__container"}
                          extraClassname={"money-withdrawal__input__withdrawal"}
                          onChange={handleChange}
                          value={values.firstLastName}
                        />
                      </div>
                    </>
                  )}
                </div>
                {selectPersonType === User.type.natural ?
                  (<div className="money-withdrawal__row">
                    <div className="money-withdrawal__column">
                      <h5 className="money-withdrawal__column__title">{t("moneyWithdrawal.secondLastName")}</h5>
                      <Input
                        label={t("moneyWithdrawal.secondLastName")}
                        name="secondLastName"
                        disabled={true}
                        id="secondLastName"
                        className={"money-withdrawal__input__container"}
                        extraClassname={"money-withdrawal__input__withdrawal"}
                        onChange={handleChange}
                        value={values.secondLastName}
                      />
                    </div>
                    <div className="money-withdrawal__column">
                      <h5 className="money-withdrawal__column__title">{t("moneyWithdrawal.documentNumber")}</h5>
                      <div className="money-withdrawal__column__same__row">
                        <Select
                          isSummary={true}
                          label={t("register.documentType")}
                          type="documentType"
                          disabled={true}
                          name="documentType"
                          testId="document-type-input"
                          className="money-withdrawal__select__withdrawal__small_natural money-withdrawal__select__withdrawal__document__type money-withdrawal__select__withdrawal money-withdrawal__select__withdrawal__small"
                          id="documentType"
                          options={documentTypes}
                          onBlur={handleBlur}
                          value={values.documentType}
                        />
                        <div className="money-withdrawal__input__container__amount__field__divider money-withdrawal__input__container__amount__field__divider__disabled"></div>
                        <Input
                          label={t("moneyWithdrawal.documentNumber")}
                          name="documentNumber"
                          disabled={true}
                          id="document_number"
                          className={"money-withdrawal__input__container__document__field"}
                          extraClassname={"money-withdrawal__input__withdrawal money-withdrawal__input__withdrawal__no__radius money-withdrawal__input__withdrawal__align__left"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.documentNumber}
                        />
                      </div>
                    </div>
                  </div>) :
                  (<></>)}
              </div>
              <div className="money-withdrawal__middle__text">{t("moneyWithdrawal.middleText")}</div>
              <div className="money-withdrawal__row__container">
                <div className="money-withdrawal__row__title">{t("moneyWithdrawal.bankInfo")}</div>
              </div>
              <div className="money-withdrawal__row__container">
                <div className="money-withdrawal__row"
                >
                  <div className="money-withdrawal__column">
                    <h5 className="money-withdrawal__column__title">{t("moneyWithdrawal.bankName")}</h5>
                    <div className={!(touched.bank && errors.bank) ? "money-withdrawal__select__bank" : "money-withdrawal__select__bank money-withdrawal__select__bank__error"}>
                      <Dropdown
                        name="bank"
                        id="bank"
                        dataTestId={"money-withdrawal-select-bank"}
                        initialLabel={t("moneyWithdrawal.select")}
                        options={banks}
                        onChange={handleBankChange}
                        valueSelected={values.bank}
                        onBlur={handleBankBlur}
                      />
                    </div>
                    {(touched.bank && errors.bank) && (
                      <p data-testId="alert-error-bank" className="money-withdrawal__input__container__text__alert">
                        {t(errors.bank)}
                      </p>
                    )}
                  </div>
                  <div className="money-withdrawal__column">
                    <h5 className="money-withdrawal__column__title">{t("moneyWithdrawal.bankAccountType")}</h5>
                    <div className={!(touched.bankAccountType && errors.bankAccountType) ? "money-withdrawal__select__bank__type money-withdrawal__select__bank " : "money-withdrawal__select__bank__type money-withdrawal__select__bank money-withdrawal__select__bank__error"}>
                      <Dropdown
                        name="bankAccountType"
                        id="bankAccountType"
                        dataTestId={"money-withdrawal-select-account-type"}
                        initialLabel={t("moneyWithdrawal.select")}
                        options={bankAccountTypes}
                        onChange={handleBankTypeChange}
                        onBlur={handleBankTypeBlur}
                        valueSelected={values.bankAccountType}
                      />
                    </div>
                    {(touched.bankAccountType && errors.bankAccountType) && (
                      <p data-testId="alert-error-bank-account" className="money-withdrawal__input__container__text__alert">
                        {t(errors.bankAccountType)}
                      </p>
                    )}
                  </div>
                </div>
                <div className="money-withdrawal__row"
                >
                  <div className="money-withdrawal__column">
                    <h5 className="money-withdrawal__column__title">{t("moneyWithdrawal.bankNumber")}</h5>
                    <Input
                      label={t("moneyWithdrawal.bankNumber")}
                      name="bankNumber"
                      id="bank_number"
                      data-testId="money-withdrawal-input-bank-number"
                      placeholder={""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bankNumber}
                      error={touched.bankNumber && errors.bankNumber}
                      showTooltip={false}
                      className={touched.bankNumber && errors.bankNumber ? "money-withdrawal__input__container money-withdrawal__input__container__error" : "money-withdrawal__input__container"}
                      extraClassname={"money-withdrawal__input__withdrawal"}
                    />
                    {(touched.bankNumber && errors.bankNumber) && (
                      <p data-testId="alert-error-bank-number" className="money-withdrawal__input__container__text__alert">
                        {t(errors.bankNumber)}
                      </p>
                    )}
                  </div>
                  <div className="money-withdrawal__column">
                    <h5 className="money-withdrawal__column__title">{t("moneyWithdrawal.confirmBankNumber")}</h5>
                    <Input
                      label={t("moneyWithdrawal.bankNumber")}
                      name="confirmBankNumber"
                      id="confirm_bank_number"
                      data-testId="money-withdrawal-input-bank-number-confirm"
                      placeholder={""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmBankNumber}
                      showTooltip={false}
                      error={touched.confirmBankNumber && errors.confirmBankNumber}
                      className={touched.confirmBankNumber && errors.confirmBankNumber ? "money-withdrawal__input__container money-withdrawal__input__container__error" : "money-withdrawal__input__container"}
                      extraClassname={"money-withdrawal__input__withdrawal"}
                    />
                    {(touched.confirmBankNumber && errors.confirmBankNumber) && (
                      <p data-testId="alert-error-bank-number-confirm" className="money-withdrawal__input__container__text__alert">
                        {t(errors.confirmBankNumber)}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <p className="money-withdrawal__footer__text">
                {t("moneyWithdrawal.descriptionFooter1")}
                <b>{t("moneyWithdrawal.descriptionFooter2")}</b>
                {t("moneyWithdrawal.descriptionFooter3")}
              </p>
              <div className="money-withdrawal__button__container">
                <button
                  className="money-withdrawal__button"
                  id="send_button"
                  type="submit"
                  data-testid="save-button"
                  disabled={!(isValid && dirty) || investmentCancellation?.isRequesting}
                >
                  {t("moneyWithdrawal.saveAndContinue")}
                </button>
              </div>
            </form>
          ) }
        }
      </Formik>
    </div>
  );
};

MoneyWithdrawal.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      response: PropTypes.arrayOf([
        PropTypes.shape({}),
        PropTypes.shape({
          _bank: PropTypes.string
        })
      ]),
      isValidated: PropTypes.bool
    })
  }).isRequired
};

export default MoneyWithdrawal;