import styled, { css } from "styled-components";

export const DropDownHeader = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  padding: 3px;
  height: 41px;
  padding-left: 16px;
  font-style: normal;
  font-weight: 500;
  align-items: center;
  font-size: 14px;
  line-height: 147%;
  ${({ isOpen, theme }) => css`
    background: ${theme.white};
    border-radius: ${!isOpen ? "5px" : "5px 5px 0 0"};
    font-family: ${theme.fontFamily.primary};
    color: ${theme.GunPowder};
  `};
`;
export const WrapperRef = styled.div``;

export const DropDownSelect = styled.div`
  overflow-x: hidden;
  position: relative;
  ${({ showScroll }) => css`
    height: ${!showScroll ? "142px" : "206px"};
    overflow-y: ${!showScroll ? "hidden" : "scroll"};
    width: ${!showScroll ? "100%" : "97%"};
  `};

  &::-webkit-scrollbar {
    width: 16px; 
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border: 1.5px solid #D4D7D9;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #D4D7D9;
    border-radius: 8px;
  }
`;

export const DropDownListContainer = styled.div`
  position: absolute;
  z-index: 20;
  border-radius: 8px;
`;

export const DropDownList = styled.ul`
  cursor: pointer;
  padding: 0;
  margin: 0;
  transition: opacity 0.3s, visibility 0.3s;
  background: ${({ theme }) => theme.white};
  border-radius: 0 0 5px 5px;
  animation: on 0.2s ease-in;
  animation-fill-mode: forwards;
  z-index: 20;

  @keyframes on {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const ListItem = styled.li`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 3px;
  height: 41px;
  padding-left: 16px;
  font-style: normal;
  font-weight: 500;
  align-items: center;
  font-size: 14px;
  line-height: 147%;
  z-index: 20;

  ${({ theme, widthOption }) => css`
    background: ${theme.white};
    font-family: ${theme.fontFamily.primary};
    color: ${theme.GunPowder};
    width: ${`${widthOption}px`};
  `};
  &:last-child {
    border-radius: 0 0 5px 5px;
    box-shadow: 0px 8px 26px -18px rgba(0, 0, 0, 0.25);
  }
`;

export const DownArrow = styled.img`
  position: absolute;
  right: 16px;
  animation: ${({ isOpen }) =>
    isOpen ? "spinOut .3s forwards" : "spinIn .3s forwards"};

  @keyframes spinOut {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }
  @keyframes spinIn {
    from {
      transform: rotate(180deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`;
