const Routes = {
  getRegisterRouteBaseOn(personType) {
    return `/register/${personType}?personType=${personType}`;
  },
  register: "/register",
  dashboardCampaigns: "/dashboard-campaigns",
  myCompany: "/my-company",
  showCase: "/",
  myCertificates: "/my-certificates"
};
export default Routes;
